.catalogues-container {
  background-color: #f8f9f9;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.catalogues-table-container {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  height: 100vh;
  padding-bottom: 100px;
}

.catalogues-search-bar-container {
  float: right;
  margin: 10px 0px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-left: 30px;
  padding-right: 45px;
}

.catalogues-search-bar-row:hover {
  /* border: 2px solid blue; */
  /* cursor: pointer; */
}

.load-more-catalogues-button-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.load-more-catalogues-button {
  font-size: 12px !important;
}

.incoming-po-text-orange {
  color: orange !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: center;
}

.incoming-po-text-green {
  color: rgb(10, 199, 10) !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: center;
}

.incoming-po-text-purple {
  color: purple !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: center;
}

.incoming-po-text-red {
  color: red !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: center;
}
