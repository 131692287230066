.color-card {
    display: flex;
    align-items: center;
  }

  .color-text{
    font-size: 10px;
  }
  
  .color-box {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  
  .color-box.red {
    background-color: red;
  }
  
  .color-box.orange {
    background-color: orange;
  }
  
  .color-box.green {
    background-color: green;
  }
  