
  input[type="file"] {
    margin-bottom: 10px;
  }

  .bulk-operations-container{
    overflow-y: scroll;
    background-color: #f8f9f9;
    height: 100vh;
    
  }

  .files-history-header{
    display: flex;
    flex-direction: row;
  }
  
  /* Style for the table */
  /* table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
   */