.bin-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
    gap: 4px;
    margin-top: 8px;
  }
  
  .bin-item {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .bin-id {
    font-weight: bold;
    font-size: 14px;
  }
  
  .quantity {
    font-size: 1.2rem;
  }
  
  .add-button,
  .subtract-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    padding-left:  10px;
    padding-right: 10px;
    padding-top:  0px;
    padding-bottom: 0px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-button:hover,
  .subtract-button:hover {
    background-color: #0056b3;
  }
  