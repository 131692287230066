.inventory-bulk-operations-main-container {
  background: #f8f9f9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.inventory-bulk-operations-main-container {
  padding: 20px;
}

.secondary-listings-bulk-operations-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.operations-container {
  display: flex;
  gap: 20px;
  flex-direction: row;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 50px;
}

.operation-section {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.operation-section h3 {
  margin-bottom: 10px;
  text-align: center;
}

.operation-content {
  flex-grow: 1;
  max-height: 300px; /* Set a max height to make it scrollable */
  overflow-y: auto;
  padding: 10px;
}

.operations-list-item {
  width: 100%;
  font-size: 12px;
  padding: 20px;
  border: 1px solid black;
  margin-bottom: 2px;
}

.operations-list-item:hover {
  cursor: pointer;
  background-color: #ddd;
}

.loading-page-horizontal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(253, 252, 252, 0.9); /* Semi-transparent background */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--text-color-on-white);
  font-weight: bold;
  z-index: 110;
}

.final-submit-button-container-bulk-ops {
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ddd;
}

.final-submit-button-container-bulk-ops > button {
  font-size: 10px !important;
}

.red-row td {
  background-color: rgb(235, 165, 165) !important;
}

.green-row td {
  background-color: rgb(165, 235, 180) !important;
}

.after-submission-result-action-container {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 60px;
  font-size: 12px;
}

.second-step-bulk-operations-title {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
}
