/* SearchBar.css */

.vendor-search-bar {
    display: flex;
    align-items: center;
    /* margin-bottom: 20px; */
    margin-left: 10px;
    margin-right: 16px;
    width:300px;
  }
  
  input[type="text"] {
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    flex: 1;
  }
  
  button {
    padding: 4px 8px;
    background-color: var(--eCraft-text-color-primary-dark); /* Customize the button color */
    color: #fff; /* Text color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    margin-left: 2px;
  }
  
  button:hover {
    background-color: #0056b3; /* Hover color */
  }
  