body {
  --eCraft-color-primary: #16275c!important;
  --eCraft-color-primary-dark: #f8f9f9;
  --eCraft-color-grey: #afb3b3;
  --eCraft-color-grey_hover: #e7ecec;
  --eCraft-text-color-primary-dark: #16275c;
  --eCraft-ripple-color: #859ff3;
  overflow: hidden;
  zoom: 100%;
  
}

.app__body {
  display: flex;

  height: 100vh;
  width: 100%;
}
.inside_app {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.btn-outline-primary {
  --bs-btn-color: #16275c!important;
  --bs-btn-border-color: #16275c!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #16275c!important;
  --bs-btn-hover-border-color: #16275c!important;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #16275c!important;
  --bs-btn-active-border-color: #16275c!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #16275c!important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #16275c!important;
  --bs-gradient: none;
}

a{
  color: black !important;
  text-decoration: none !important;
}