.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vendor-details {
  background: #f8f9f9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  height: 100%;
  width: 100%;
}

.main-container {
  display: flex;
  flex-direction: row;
}

.vendor-action-container {
  width: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 100px;
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.catalogue-items-container {
  width: 70%;
  max-height: 100vh;
}

.vendors-info-card {
  width: 100%; /* Adjust as needed to fit four cards in a row */
  /* Fixed height */
  margin-bottom: 50px;
  padding: 14px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 6px;
}

.vendors-info-card h4 {
  font-size: 12px; /* Reduced font size */
  margin: 5px 0; /* Adjust margin as needed */
}

.vendors-view-card-title {
  word-wrap: break-word;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.specialities-container {
  font-size: 14px;
}

.grid-1-cells {
  display: grid;
  grid-template-columns: repeat(
    1,
    1fr
  ); /* Adjust the number of columns as needed */
  gap: 8px;
  margin-top: 8px;
}

.grid-item-details-card {
  border: 1px solid #ccc;
  padding: 4px;
  /* text-align: center; */
}

label {
  font-size: 12px;
  font-weight: normal !important;
  margin: 0px !important;
}

.vendors-info-card input {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--eCraft-text-color-primary-dark);
}

.vendor-action-button {
  margin: 2px 0px;
  font-size: 12px !important  ;
}

.vendor-action-buttons-container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}
.vendor-action-buttons-container button {
  width: 100%;
}

.grid-2-cells {
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* Adjust the number of columns as needed */
  gap: 8px;
  margin-top: 8px;
}

.vendor-action-container::-webkit-scrollbar {
  width: 0.2em; /* Set a very thin width for Webkit browsers like Chrome and Safari */
}

.vendor-action-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set a transparent background for the thumb */
}

/* For Webkit browsers, you can also set transparent background for scrollbar track */
.vendor-action-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.vendor-listings-search-bar-container {
  margin: 24px 0px;
}

.vendor-details-button-container {
  display: flex;
}

.vendor-details-button-container button {
  font-size: 12px;
  margin: 4px;
}

/* You can style the .value or .grid-item as needed */
.vendor-details-button-container button:disabled {
  background-color: #ccc;
}

.vendor-details-button-container button {
  background-color: #16275c;
  font-size: 10px;
  margin-right: 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  padding: 2px 8px;
}

.vendor-action-container input:focus {
  outline: none;
}
.vendor-action-container input {
  background-color: #ccc;
}
.vendor-action-container input:disabled {
  background-color: white;
}

.speciality-edit-buttons {
  display: flex;
}

/* this component */
.vendor-info {
  font-size: 12px;
}

.vendor-info p {
  margin: 0;
}

.po-item-card {
  font-size: 12px;
  border: 1px solid #ccc;
  padding: 4px;
}

.po-item-card:hover {
  border: 1px solid blue;
  cursor: pointer;
}

/* .purchase-order-vendor-listings-container{
  padding-right: 20px;
  padding-left:5px
} */

.add-item-quantity-card-title {
  font-size: 14px !important;
}

.add-item-quantity-card-button-container button {
  font-size: 12px !important;
}

.image-placeholder-catalogue {
  cursor: pointer;
  padding: 5px;
  height: 400px;
  display: flex;
  flex-direction: column;
}
.image-placeholder-catalogue-spinner {
  cursor: pointer;
  padding: 5px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.theme-image-container {
  max-width: 100%;
  height: 370px;
}

.theme-image-spiner-container {
  max-width: 100%;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-placeholder-catalogue img {
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
}

.theme-name {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

/* Style for the Form.Check components */
.FormCheck {
  margin-bottom: 10px; /* Adjust as needed */
}

/* Style for the radio buttons */
.FormCheck input[type="radio"] {
  /* Hide the default radio button */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 1px solid #ccc; /* Border color */
  border-radius: 50%; /* Make it round */
  width: 20px; /* Width of the radio button */
  height: 20px; /* Height of the radio button */
  vertical-align: middle; /* Align with the text */
  margin-right: 10px; /* Spacing between radio button and label */
}

/* Style for the radio button labels */
.FormCheck label {
  cursor: pointer;
  font-size: 16px; /* Adjust as needed */
  vertical-align: middle; /* Align with the radio button */
}

.theme-page-heading {
  font-weight: bold;
  color: var(--eCraft-text-color-primary-dark);
  font-size: 14px;
}

.grid-1-cells-theme-options {
  display: grid;
  grid-template-columns: repeat(
    1,
    1fr
  ); /* Adjust the number of columns as needed */
  gap: 8px;
  margin-top: 8px;
  width: 50%;
}

.grid-item-theme {
  border: 1px solid #ccc;
  padding: 4px;
}

.catalogue-price-apply-button,
.download-pdf-button {
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 4px;
}

.image-being-downloaded-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-row {
  border: 1px solid grey;
  width: 40%;
}

.details-page-separator {
  display: flex;
  align-items: center;
  justify-content: center;
}
