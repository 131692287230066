.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-purchase-order-details-container {
  background: #f8f9f9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.upload-incoming-po-container {
  width: 60%;
  min-width: 400px;
  padding-left: 30px;
  padding-right: 30px;
}

.upload-incoming-po-container-insider {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(218, 213, 213);
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  /* Set the minimum width to prevent shrinking beyond this size */
}

.disabled-hover {
  cursor: not-allowed;
}

.po-items-container-details {
  margin-top: 30px;
  font-size: 10px !important;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 100px;
  box-sizing: border-box; /* Ensures padding is included in the width */
  overflow-x: auto; /* Adds horizontal scroll if table overflows */
  overflow-y: auto; /* Adds vertical scroll */
  max-height: 100vh; /* Adjust the height for scrolling */
}

.po-items-container-details table {
  width: 100%;
  border-collapse: collapse;
}

.po-items-container-details th {
  position: sticky;
  top: 0;
  background-color: rgb(
    221,
    239,
    252
  ) !important; /* Ensure the header has a background */

  z-index: 10; /* Ensures the header stays above other rows */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for distinction */
}

.po-items-title {
  font-size: 10px !important;
}

.incoming-purchase-order-details-table-container {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10px;
  width: 60%;
  min-width: 400px;
}

.table-auto-width {
  table-layout: auto;
  width: 100%;
}

.nowrap {
  white-space: nowrap;
  width: 150px; /* Ensures minimal width */
  font-weight: bolder;
  padding-right: 12px;
}

.table-auto-width td {
  vertical-align: top; /* Optional: Align text to the top if needed */
}

.error-cell {
  background-color: rgba(255, 0, 0, 0.1) !important; /* Light red background */
  position: relative;
}

.info-cell {
  background-color: rgba(
    236,
    236,
    32,
    0.5
  ) !important; /* Light red background */
  position: relative;
}

.info-header {
  background-color: rgba(
    0,
    195,
    255,
    0.1
  ) !important; /* Light red background */
  position: relative;
}

.error-cell:hover .tooltip {
  display: block;
}

.tooltip {
  visibility: hidden;
  background-color: rgb(243, 246, 179);

  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the element */

  white-space: nowrap;
  font-size: 10px !important;

  /* Optional: Add an arrow */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%; /* Bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.error-cell:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.po-items-container-details > table th,
.po-items-container-details > table td {
  text-align: center;
  vertical-align: middle; /* For vertical centering */
}

.upload-snapshot-heading-text {
  font-size: 14px;
  font-weight: bold;
}

.upload-snapshot-button {
  font-size: 10px !important;
  min-width: 300px;
}

.upload-button {
  font-size: 12px !important;
}

.po-items-container-details .error-count-container {
  color: red;
  padding-bottom: 4px;
}

.po-items-container-details .success-container {
  color: green;
  padding-bottom: 4px;
}

.upload-po-final-data {
  max-width: 200px;
  font-size: 10px !important;
}

.incoming-po-text-orange {
  color: orange !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: left;
}

.incoming-po-text-green {
  color: rgb(10, 199, 10) !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: left;
}

.incoming-po-text-purple {
  color: purple !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: left;
}

.incoming-po-text-red {
  color: red !important;
  font-weight: bold;
  font-size: 10px !important;
  text-align: left;
}

.marked-green {
  color: rgb(10, 199, 10) !important;
  font-weight: bold;
}

.marked-red {
  color: red !important;
  font-weight: bold;
}

.expandable-content-po {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease-out;
  text-align: left;
}
.expandable-content-po td {
  background-color: rgb(242, 246, 241) !important;
}

.expanded {
  max-height: 150px; /* Adjust according to the content height */
}

.collapsed {
  max-height: 0;
}

.expanded-details {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.po-expanded-cell {
  text-align: left !important;
  padding: 15px;
  background-color: #f4f6f9; /* Light background for distinction */
}

.expanded-content {
  padding: 10px;
}

.expanded-item {
  margin-bottom: 8px; /* Space between each item */
  font-size: 12px;
}

.expanded-item strong {
  color: #333;
}

.expanded-additional {
  margin-top: 12px;
  font-style: italic;
  color: #666;
}

.expanded-additional em {
  color: #999; /* Subtle color for additional details label */
}

.incoming-po-search-bar-row.expanded-main-row td {
  background-color: rgb(242, 246, 241) !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff; /* Ensure the header has a background */
  z-index: 100; /* Make sure it stays above the other table rows */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add a slight shadow for separation */
}

.incoming-po-search-bar-row {
  --bs-table-hover-bg: transparent;
}

.label-count-container {
  width: 200px;
  min-width: 40%;
  border: 1px solid gainsboro;
  padding: 12px;
  border-radius: 10px;
}

.label-count-section-header {
  font-size: 18px;
}

.fixed-label-details-form-container {
  max-width: 60%;
}

.maunfactured-marketed-by-container {
  width: 100%;
}

.fixed-label-picker-container-main {
  border: 1px solid #ccc;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; /* Ensure child elements wrap within the container */
  align-items: stretch;
}

/* Style the select box */

.fixed-label-picker-select {
  width: 100%; /* Ensures the select element spans the full width */
  max-width: 100%; /* Prevents the select from exceeding container width */
  white-space: normal; /* Allows text to wrap within the select */
  overflow: hidden; /* Ensures the overflow is controlled */
  text-overflow: ellipsis; /* Applies ellipsis for long text */
  display: block; /* Ensures it behaves as a block-level element */
  line-height: 1.2; /* Adjusts line height for better readability */
  height: auto; /* Adjusts height dynamically based on content */
  padding: 8px; /* Adds padding for a better look */
  box-sizing: border-box; /* Includes padding in width/height calculation */
  font-size: 14px; /* Adjust font size as needed */
}

.fixed-label-picker-container-main select {
  width: 100%; /* Make the select element span the entire width */
  box-sizing: border-box; /* Include padding and border in width calculation */
}
