/* CustomDateTimePicker.css */

.date-time-picker-container-main{
  border: 1px solid #ccc;
  width: 100%;
  padding:8px
}

.date-time-picker-container {
    display: flex;
    flex-direction: row;
    align-items: center;    
  }
  
.date-time-picker-select {
  padding: 8px;
  margin: 5px;
  font-size: 16px;
}

.date-time-picker-button{
  font-size: 12px !important;
  padding: 4px 10px !important;
}

.date-time-picker-button:hover {
  background-color: #2980b9;
}

.selected-date-time {
  margin-top: 20px;
  font-size: 18px;
}

.date-time-picker-selected-date-container{
  font-size: 12px;
}

.set-expiry-container{
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.date-time-picker-container-main select{
  font-size: 12px;
}