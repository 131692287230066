
.form-container{
    width: 50%;
    min-height: 400px;
}

.purchase-order-create-button-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.purchase-order-create-button{
    font-size: 12px !important;
    padding: 4px 10px  !important;
}
