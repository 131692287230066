.sidebarOption {
    display: flex;
    padding: 10px 0;
    font-size: 14px;
    padding-left: 10px;  
    /* color: var(--eCraft-text-color-primary-dark);   */
    color: white;
    align-items: center;
    cursor: pointer;
  }
  
  .sidebarOption:hover {
    /* background-color: var(--eCraft-color-grey_hover); */
    background-color : #66655f;
  }
  
  .sidebarOption__icon {    
    margin-right: 10px;
    padding-top: 2px;
  }
  
  .sidebarOption__name {
    padding-bottom: 2px;
    font-size: 12px;
  }
  