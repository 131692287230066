.cost-price-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: white;
  font-weight: bold;
}

.cost-price-container input[type="text"] {
  height: auto;
  border: none;
  background-color: transparent;
  font-weight: bold;
}

.vendor-listing-card {
  width: 100%;
  height: 360px; /* Fixed height */
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: white;
}

.vendor-listing-card:hover {
  border: 1px solid #007bff;
}

.cost-price-container input:focus {
  outline: none;
}

.offcanvas-body input[type="text"],
.offcanvas-body input[type="number"] {
  height: auto;
  border: none;
  background-color: transparent;
}

.offcanvas-body input:focus {
  outline: none;
}

.offcanvas-body .grid-item-details-card {
  border: 1px solid #ccc;
  padding: 4px;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
}

.previous-cost-price-container .grid-item-details-card {
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 12px;
}

.cost-price-timeline-item {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.cp-ls {
  display: flex;
  justify-content: space-between;
}

.offcanvas-body input[type="number"] {
  margin-top: 8px;
}

.offcanvas-body button:disabled {
  background-color: #ccc;
}

.offcanvas-body button {
  background-color: #16275c;
  font-size: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  padding: 2px 8px;
}

.offcanvas-button-container {
  margin-top: 12px;
  display: flex;
  flex-direction: row-reverse;
}

label {
  font-size: 12px;
  font-weight: normal !important;
  margin: 0px !important;
}

.previous-cost-price-container {
  margin-top: 30px;
}

.last-modified {
  font-size: 10px !important;
}

.offcanvas-title-and-navigator {
  display: flex;
  flex-direction: column;
}

.offcanvas-navigator {
  font-size: 12px;
  color: #007bff;
}

.offcanvas-navigator.red {
  font-size: 12px;
  color: red;
}

.offcanvas-navigator:hover {
  cursor: pointer;
}

.vendor-listings-card-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(170px, 1fr)
  ); /* Adjust the number of columns as needed */
  gap: 6px;
  margin-top: 8px;
  padding-bottom: 300px;
}
