@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merienda&family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&family=Merienda&family=Pacifico&display=swap");
.main_heading {
  /* font: 100 18px/1.5 "Pacifico", cursive; */

  /* font: 100 27px/1.5 "Cookie", cursive; */
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
  margin-left: 15px;
  color: var(--eCraft-text-color-primary-dark);
  font-size: 16px;
  font-weight: bold;
  /* font-family: ; */
  /* font-family: 'Pacifico', cursive; */
  /* font-family: ; */
  /* color: #2b2b2b; */
  /* text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1), 7px 7px 0px rgba(0, 0, 0, 0.05); */
}
.main_heading:hover{
  cursor: pointer;
}
/* var(--eCraft-color-primary-dark); */
.header {
  display: flex;
  /* position: fixxed; */
  width: 100%;
  padding: 5px 0;
  background-color: white;
  /* background-color: #bfb7b7; */
  justify-content: space-between;
  color: var(--eCraft-text-color-primary-dark);
  border-bottom: 0.5px solid rgb(216, 211, 211);
  /* box-shadow: 0 2px 5px 1px rgb(64 60 67 / 19%); */
  /* box-shadow: 0 2px 0 0 rgb(64 60 67 / 19%); */

  /* background: linear-gradient(0deg, #fff, #b2ccee 100%); */
}

.header__left {
  flex: 1;
}

.header__avatar {
  margin-right: 30px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* border: 1px solid gray; */
  object-fit: cover;
}

.header__right {
  display: flex;
  align-items: center;
}

.header__userInfo {
  font-family: "Times New Roman", Times, serif;
  padding: 5px;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 700;
}

.header__logo {
  height: 50px;
}
.logo {
  height: 45px;
  margin-left: 25px;
  /* background-color: red; */
}
.logo_small {
  height: 40px;
  margin-left: -5px;
  margin-top: auto;
  margin-bottom: auto;
}
.header__heading {
  color: var(--eCraft-text-color-primary-dark);
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
  padding-left: 20px;
  display: flex;
  /* background-color: yellowgreen; */
}
.user_name{
  margin-left: auto;
  margin-right: 40px;
  font-size: 14px;
  display: flex;
}

.header_search_bar {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 50px;

  position: fixed;
  margin-bottom: 80px;
}
.header__search {
  display: flex;
  border: 1px solid black;

  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border: 1px solid transparent;
  color: black;
  border-radius: 10px;
  padding: 2px;
  max-width: 200px;
  margin-right: 100px;
  margin-top: 30px;
  right: 0;
  position: fixed;
  background-color: #fff;

  align-items: center;
}
.header__search > input {
  border: none;
  width: 100%;
  background-color: transparent;
}

.header__search > button {
  display: none !important;
}

.header__search > input:focus {
  outline: none;
}

.header_search_icon {
  font-size: 18px !important;
}
.my_profile_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 1px solid gray; */
  object-fit: cover;
  /* box-shadow: 0 2px 5px 1px rgb(64 60 67 / 30%);
  border: 1px solid transparent; */
}
.my_profile_img_small {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  /* border: 1px solid gray; */
  object-fit: cover;
}
.profile_details {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  /* margin-bottom: 20px; */
  margin-top: 5px;
  margin-right: 20px;
}
.user__name {
  font-size: 25px;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
  color: var(--eCraft-text-color-primary-dark);
}

.user__name_small {
  /* font-size: 22px; */
  font-size: 25px;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
  color: var(--eCraft-text-color-primary-dark);
}
.user__id {
  font-family: "Times New Roman", Times, serif;
  /* color: var(--eCraft-text-color-primary-dark); */
  font-size: 16px;
  margin-left: 2px;
  margin-top: 5px;
  display: flex;
  font-weight: 500;
}
.user__mobile {
  font-family: "Times New Roman", Times, serif;
  /* color: var(--eCraft-text-color-primary-dark); */
  font-size: 16px;
  margin-left: 2px;

  display: flex;
  font-weight: 500;
}
.user__id_small {
  font-family: "Times New Roman", Times, serif;
  /* color: var(--eCraft-text-color-primary-dark); */
  font-size: 16px;
  /* margin-left: 2px; */
  margin-top: 5px;
  display: flex;
  font-weight: 500;
}
.user__id_small_mobile {
  font-family: "Times New Roman", Times, serif;
  /* color: var(--eCraft-text-color-primary-dark); */
  font-size: 16px;
  /* margin-left: 2px; */
  /* margin-top: -4px; */
  display: flex;
  font-weight: 500;
}
