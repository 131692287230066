/* App.css (or your CSS file) */

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Ensure the page takes up at least the viewport height */
    background-color: #f5f5f5; /* Background color for the entire page */
  }
  
  h3 {
    margin-bottom: 20px;
    color: var(--eCraft-text-color-primary-dark); ;
  }
  
  .login-page form {
    background-color: #fff;
    width: 20%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for the form */
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .login-page label {
    display: block;
    margin-bottom: 5px;
    color: #333; /* Text color for labels */
  }
  
  input[type="text"],
  input[type="password"]
  {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc; /* Grey border for input fields */
    border-radius: 3px;
    font-size: 16px;
    height: 40px;
  }

  .checkbox-container{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .checkbox-label{
    font-size: 12px;
    color: var(--eCraft-text-color-primary-dark);
    display: flex; /* Use flexbox to align checkbox and text horizontally */
    align-items: center; /* Align items vertically in the center */
  }
  .checkbox-label:hover, input[type="checkbox"]:hover{
    cursor: pointer;
  }

  input[type="checkbox"]{
    margin-right: 4px;
  }
  
  button[type="submit"] {
    background-color: var(--eCraft-text-color-primary-dark); ;
    color: #fff; /* Button text color */
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3; /* Button background color on hover */
  }
  