.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-purchase-order-details-container {
  background: #f8f9f9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.upload-incoming-po-container {
  width: 60%;
  min-width: 400px;
  padding-left: 30px;
  padding-right: 30px;
}

.upload-incoming-po-container-insider {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(218, 213, 213);
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  /* Set the minimum width to prevent shrinking beyond this size */
}

.disabled-hover {
  cursor: not-allowed;
}

.po-items-container {
  margin-top: 30px;
  font-size: 10px !important;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 100px;
  box-sizing: border-box; /* Ensures padding is included in the width */
  overflow-x: auto; /* Adds horizontal scroll if table overflows */
}

.po-items-title {
  font-size: 10px !important;
}

.incoming-purchase-order-details-table-container {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10px;
  width: 60%;
  min-width: 400px;
}

.table-auto-width {
  table-layout: auto;
  width: 100%;
}

.nowrap {
  white-space: nowrap;
  width: 150px; /* Ensures minimal width */
  font-weight: bolder;
  padding-right: 12px;
}

.table-auto-width td {
  vertical-align: top; /* Optional: Align text to the top if needed */
}

.error-cell {
  background-color: rgba(255, 0, 0, 0.1) !important; /* Light red background */
  position: relative;
}

.info-cell {
  background-color: rgba(
    236,
    236,
    32,
    0.5
  ) !important; /* Light red background */
  position: relative;
}

.info-header {
  background-color: rgba(
    0,
    195,
    255,
    0.1
  ) !important; /* Light red background */
  position: relative;
}

.error-cell:hover .tooltip {
  display: block;
}

.tooltip {
  visibility: hidden;
  background-color: rgb(243, 246, 179);

  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the element */

  white-space: nowrap;
  font-size: 10px !important;

  /* Optional: Add an arrow */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%; /* Bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.error-cell:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.po-items-container > table th,
.po-items-container > table td {
  text-align: center;
  vertical-align: middle; /* For vertical centering */
}

.upload-snapshot-heading-text {
  font-size: 14px;
  font-weight: bold;
}

.upload-snapshot-button {
  font-size: 10px !important;
  min-width: 300px;
}

.upload-button {
  font-size: 12px !important;
}

.po-items-container .error-count-container {
  color: red;
  padding-bottom: 4px;
}

.po-items-container .success-container {
  color: green;
  padding-bottom: 4px;
}

.upload-po-final-data {
  max-width: 200px;
  font-size: 10px !important;
}
