.invoice-price-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  /* justify-content: center;
  align-items: center;
  padding : 0px 4px ;
  margin-top: 4px;
  margin-bottom: 4px;   
  font-weight: bold; */
}

.invoice-price-items-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  /* font-weight: bold; */
}

.invoice-price-items-container input[type="text"] {
  height: auto;
  border: none;
  background-color: transparent;
  font-weight: bold;
}

.invoice-price-items-container input:focus {
  outline: none;
}

.offcanvas-body input[type="text"],
.offcanvas-body input[type="number"] {
  height: auto;
  border: none;
  background-color: transparent;
}

.offcanvas-body input:focus {
  outline: none;
}

.offcanvas-body .grid-item-details-card {
  border: 1px solid #ccc;
  padding: 4px;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
}

.previous-cost-price-container .grid-item-details-card {
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 12px;
}

.cost-price-timeline-item {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.cp-ls {
  display: flex;
  justify-content: space-between;
}

.offcanvas-body input[type="number"] {
  margin-top: 8px;
}

.offcanvas-body button:disabled {
  background-color: #ccc;
}

.offcanvas-body button {
  background-color: #16275c;
  font-size: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  padding: 2px 8px;
}

.offcanvas-button-container {
  margin-top: 12px;
  display: flex;
  flex-direction: row-reverse;
}

label {
  font-size: 12px;
  font-weight: normal !important;
  margin: 0px !important;
}

.previous-cost-price-container {
  margin-top: 30px;
}

.last-modified {
  font-size: 10px !important;
}

.purchase-order-vendor-listing-card-text {
  font-weight: bold;
}

.invoice-item-card {
  height: 420px !important;
}

.listings-view-card.vendor-listing-card.blue {
  background-color: rgb(188, 206, 255);
}

.catalogue-listings-container-main {
  width: 100%;
  height: 100%;
  padding: 15px;
}
