.all-listings-only-card-container {
  /* display: flex;
    flex-wrap: wrap; */
  width: 100%;
  /* align-items: flex-start;
    justify-content: flex-start; */

  /* display: grid;
  grid-template-columns: repeat(
    7,
    1fr
  );  */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 6px;
  margin-top: 8px;
  padding: 15px;
}

.listings-view-card {
  width: 100%;
  height: 360px; /* Fixed height */
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: white;
}

.listings-view-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.listings-img-container {
  max-width: 100%;
  height: 200px;
}

.listings-img-container img {
  /* Reduced image height */
  max-width: 100%;
  height: auto;
  max-height: 200px;
  display: block;
  margin: 0 auto;
}

.listings-view-card h3 {
  font-size: 13px; /* Reduced font size */
  margin: 5px 0; /* Adjust margin as needed */
}

.listings-view-card p {
  font-size: 12px; /* Reduced font size */
  margin: 2px 0; /* Adjust margin as needed */
  word-wrap: break-word;
}

.listings-view-card-bold-text {
  font-weight: bold;
}
.listings-view-card-bold-text.red {
  color: red;
}
.listings-view-card-bold-text.green {
  color: green;
}

.listings-view-card-title {
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
}

.no-items-view {
  color: gray;
  font-size: 12px;
  display: flex;
  width: 100%;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

.sku-id-title.available {
  color: green;
  margin-top: 10px;
}

.sku-id-title.not-available {
  color: red;
  margin-top: 10px;
}

.sku-id-title.multi {
  color: var(--eCraft-text-color-primary-dark);
  margin-top: 10px;
}

.available-quantity-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: antiquewhite;
  font-weight: bold;
}

.available-quantity-container-child input[type="text"] {
  height: auto;
  border: none;
  background-color: transparent;
  font-weight: bold;
}

.available-quantity-container-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
