.forms-home-container{
    overflow-y: scroll;
    background-color: #f8f9f9;
    height: 100vh;
    display:flex;
    flex-direction: column;
}

.forms-card-container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: flex-start; /* Align items to the start of the container */
    justify-content: flex-start;
    padding: 20px;
}

.forms-card {
    width: 20%; /* Adjust as needed to fit four cards in a row */
    height: 150px; /* Fixed height */
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background: #16275c;
    margin: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .forms-card:hover{
    cursor: pointer;
  }
