.vendors-container {
  background-color: #f8f9f9;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.vendors-card-container-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: center; */
  align-items: center;
  overflow-y: scroll;
}

.vendors-card-container {
  /* display: flex; */
  /* flex-wrap: wrap;
    align-items: flex-start; /* Align items to the start of the container */
  /* justify-content: flex-start;     */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 6px;
  margin-bottom: 100px;
  padding: 15px;
}

.no-items-view {
  color: gray;
  font-size: 12px;
  display: flex;
  width: 100%;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

.vendors-view-card {
  width: 100%;
  height: 130px; /* Fixed height */
  margin-bottom: 50px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: white;
  margin: 6px;
}

.vendors-view-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.vendors-view-card h4 {
  font-size: 14px; /* Reduced font size */
  margin: 5px 0; /* Adjust margin as needed */
}

.vendors-view-card-title {
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
}

.speciality-badge {
  margin: 2px;
  padding: 4px;
}

.col-sm-8,
.col-sm-4 {
  padding: 0px !important;
}

.col-sm-4 {
  align-items: end !important;
}

.specialities-container {
  margin-top: 15px;
}

.vendor-search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
}

.vendor-search-bar-container-child {
  margin: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
}

.button-add-new-vendor {
  /* margin:2px 0px; */
  font-size: 12px !important  ;
  padding: 9px 10px !important;
}
