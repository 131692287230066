/* DynamicForm.css */

.form-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    background: white;
    margin-bottom: 200px;
    margin-top: 20px;
  } 
  
  .form-name{
    margin-bottom: 40px;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin: -10px; /* Adjust margin to create space between fields */
  }
  
  .form-field {
    flex: 1;
    margin: 10px;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .input-field {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
  }
  
  .button {
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    margin-top: 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .filled-message{
    margin-top: 20px;
  }