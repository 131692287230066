/* CSS for the SkuDetails component and overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sku-details {
    background: #f8f9f9;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: left;
    height: 100%;
    width: 100%;
  }

  .image-container {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: row;
  }
  
  .image-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 20%;
  }
  
  .image-placeholder {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 5px;
    height: 100px;
    display: flex;
  }

  .image-placeholder-packs{
    border: 1px solid #ccc;
    padding: 5px;
    height: 80px;
    width: 80px;
    display: flex;
  }
  .image-placeholder-packs:hover{
    cursor: pointer;
    border: 1px solid #007bff;
  }

  .image-placeholder-packs img {
    max-width: 100%;
    height: auto;
    width: 100%;
    object-fit: cover;
  }
  
  .image-placeholder.active {
    border: 2px solid #007bff; /* Highlight the selected image */
  }
  .packs-info-container{
    margin-left: 12px;
  }
  .packs-info-unit-contribution, .packs-info-available-quantity{
    display:flex;
    flex-direction: row;
  }
  .packs-info-unit-contribution input, .packs-info-available-quantity input{
    padding:0px 4px!important;
    height: auto;
    border: none; 
    background-color: transparent;
    font-weight: bold;
    margin: 0px 4px !important;
    
  }
  .packs-info-sku-id, .packs-and-combos-effective-available-quantity{
    font-size: 12px;
    font-weight: bold;
  }
  
  .pack-and-combos-row{
    display: flex;
    flex-direction: row;
  }

  .image-placeholder img {
    max-width: 100%;
    height: auto;
    width: 100%;
    object-fit: cover;
  }
  
  .details-container {
    width: 60%;
    padding: 20px;
    max-height: 94vh;
    overflow-y: auto;
    /* Add your styling for SKU details here */
  }

  .packs-and-combos-container{
    display: flex;
    flex-direction: column;
  }

  .zoomed-image{
    margin-left: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .zoomed-image img{
    max-width: 100%;
    height: 508px;
    width: 100%;
    object-fit: cover;
  }
  .zoomed-image h5{
    font-weight: bold;
    font-size: 12px;
    color: var(--eCraft-text-color-primary-dark);
    align-self: center;
  }

  .details-card {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  
  .details-card h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .details-card label {
    font-size : 12px;
  }
  
  .details-card input {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    /* border: 1px solid #ccc; */
    border-radius: 3px;
    background-color: #ccc;
  }

  .details-button-container button {  
    background-color: #007bff;
    font-size: 12px;
    margin-right: 5px;
    border: none;
    border-radius: 3px;
    cursor: pointer;    
    color: #fff;    
    padding: 4px 8px;
  }
  
  .details-card button:disabled {
    background-color: #ccc;
  }
  
  .details-card.editable input:disabled {
    background-color: white;
  }

  .details-card input:disabled {
    border: none; 
    /* border: 1px solid red; */
    background-color: transparent;
  }
  
  .description input:disabled {
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: var(--eCraft-text-color-primary-dark); 
  }
  
  .details-card button:hover {
    background-color: #0056b3;
  }

  .main-container{
    display: flex;
    flex-direction: row;
  }

  form{
    box-shadow: none;
    padding: 0px;
  }

  .details-first-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    font-size:18px;
    color: var(--eCraft-text-color-primary-dark);
  }

  .details-first-row.small{
    font-weight: bold;
    font-size:12px;
    color: var(--eCraft-text-color-primary-dark);
    display: flex;
    flex-direction: row;
  }

  .grid-3-cells{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
    gap: 8px;
    margin-top: 8px;
  }
  .grid-2-cells{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Adjust the number of columns as needed */
    gap: 8px;
    margin-top: 8px;
  }
  .grid-4-cells{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
    gap: 8px;
    margin-top: 8px;
  }
  
  .sales-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-gap: 4px; /* Gap between grid items */
    margin-top: 8px;
  }

  .sales-grid.sub {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Two columns */
    grid-gap: 4px; /* Gap between grid items */
    margin-top: 18px;
  }
  
  .grid-item {
    border: 1px solid #ccc;
    padding: 4px;
    text-align: center;
  }
  
  .grid-item-details-card {
    border: 1px solid #ccc;
    padding: 4px;
    display: flex;
    flex-direction: column;
    /* text-align: center; */
  }
  
  
  .label {
    font-size: 12px;
    font-weight: normal !important
  }

  .packs-info-delete-container button{
    padding: 0px 4px !important;
    font-size: 12px !important;
  }
  
  .associated-vendors-bin-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
    gap: 4px;
    margin-top: 8px;
  }
  
  .associated-vendors-bin-item {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .associated-vendors-vendor-id {
    font-weight: bold;
    font-size: 14px;
  }

  .associated-vendors-name{
    font-size: 14px;
  }

  .associated-vendors-avg-cost-price{
    font-size: 14px;
    font-weight: bold;
    color: var(--eCraft-text-color-primary-dark);
  }
  .associated-vendors-bin-item:hover{
    cursor: pointer;
    border: 1px solid #007bff;
  }

  .associated-vendors-not-available, .associated-bins-not-available, .packs-and-combos-not-available{
    font-size: 12px;
  }

  .details-card select{
    margin-top:4px;
    font-size: 12px;
    padding: 4px 0px;
    border-radius: 3px;
    background-color: #ccc;
  }

  .details-card select:disabled{
    border: none; 
    /* border: 1px solid red; */
    background-color: transparent;
  }

  .listing-incoming-quantity-container{
    font-size: 12px;
  }