.purchase-orders-container{
    background-color: #f8f9f9;
    height: 100vh;
    display:flex;
    flex-direction: column;
    font-size: 12px;
}

.purchase-orders-table-container{
    overflow-y: scroll;
    display:flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    height: 100vh;
    padding-bottom: 100px;
}

.purchase-orders-table-container.no-item{
    justify-content: center;
    align-items: center;
}

.purchase-orders-search-bar-container{
    float: right;
    margin: 10px 0px;    
    display : flex;
    flex-direction: row;
    align-items: center;  
    padding-left: 30px;
    padding-right: 45px;
    justify-content: space-between;
  }

  /* .purchase-orders-search-bar-row:hover{
    border: 2px solid blue;
    cursor: pointer;
  } */

.load-more-purchase-orders-button-container{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.load-more-purchase-orders-button{
    font-size: 12px !important;
}

.expiry-date{
    font-weight: bold;
}
.expiry-date.green{
    color: green;
}
.expiry-date.red{
    color: red;
}
.expiry-date.orange{
    color: orange;
}

.colors-overview-container{
    display: flex;
    flex-direction: row;
}

.po-id-link a{
    color: blue !important;
    text-decoration: underline !important;
}