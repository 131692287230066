/* Navbar.css */

  
  .navbar-menu {
    list-style: none;
    display: flex;
  }
  
  .navbar-item {
    margin-right: 20px;
    cursor: pointer;
    font-size:12px;
    padding: 4px;
  }

  .navbar-item:hover {
    background-color : #66655f;
  }
  
  .navbar-item:last-child {
    margin-right: 0;
  }
  
  ul{
    margin-bottom: 0px !important;
  }

  .navbar-item.active {
    color: #ff9800; /* Change the color for active item */
    font-weight: bold; /* Optionally, you can make the active item bold */
  }